var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],class:_vm.tableClass,attrs:{"refs":"historiesTable","row-key":"id","header-row-class-name":"thead-light","height":_vm.autoHeight? 200 : 400,"data":_vm.tableData,"stripe":"","empty-text":"没有修改记录"}},[_c('el-table-column',{attrs:{"prop":"total_amount","label":"总额(万元)","width":"110","class-name":"pl-2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v("\n        "+_vm._s(row.total_amount ? parseFloat(row.total_amount).toFixed(2) : '0.00')+"\n    ")]}}])}),_vm._v(" "),_c('el-table-column',{attrs:{"prop":"fupin_amount","label":"扶贫额(万元)","width":"110"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v("\n        "+_vm._s(row.fupin_amount ? parseFloat(row.fupin_amount).toFixed(2) : '0.00')+"\n    ")]}}])}),_vm._v(" "),_c('el-table-column',{attrs:{"prop":"shuliang","label":"消费数量","width":"110"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v("\n        "+_vm._s(row.shuliang ? parseFloat(row.shuliang) : '0')+"\n    ")]}}])}),_vm._v(" "),(false)?_c('el-table-column',{attrs:{"prop":"daipinrenkou","label":"带贫人口","width":"110"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v("\n        "+_vm._s(row.daipinrenkou ? parseFloat(row.daipinrenkou): '0')+"\n    ")]}}],null,false,3894639626)}):_vm._e(),_vm._v(" "),_c('el-table-column',{attrs:{"prop":"productType","label":"产品类型","width":"110"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v("\n        "+_vm._s(row.productType ? row.productType: '')+"\n    ")]}}])}),_vm._v(" "),_c('el-table-column',{attrs:{"prop":"updated_at","label":"修改时间","min-width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v("\n            "+_vm._s(row.updated_at ? row.updated_at: '')+"\n        ")]}}])}),_vm._v(" "),_c('el-table-column',{attrs:{"prop":"who","label":"操作员"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }